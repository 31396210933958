.container {
  display: flex;
  height: 100vh;
  background-color: #fff;
}

.left-panel, .right-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.table-container {
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 10px;
  max-height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid rgba(216, 194, 253, 0.4);
}

th {
  position: sticky;
  top: 0;
  z-index: 1;
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 2px solid rgba(216, 194, 253, 0.6);
}

.selected-cell {
  background-color: #ffae00;
  transition: background-color 0.3s;
}

/* Updated table cell hover effect */
.table-container td.cell:hover {
  background-color: #ffae80; /* A lighter version of #ffae00 for hover effect */
  transition: background-color 0.3s;
}

/* Styling for the input element within the cell */
.table-container td.cell input[type="text"] {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  outline: none;
  transition: background-color 0.3s;
}

.table-container td.cell input[type="text"]:focus {
  background-color: rgba(255, 255, 255, 1);
}

.table-container td.cell input[type="text"]:focus-visible {
  outline: none;
}

.header-cell {
  cursor: pointer;
}

.selected-content {
  background-color: rgba(255, 174, 0, 0.1);
  border-color: rgba(255, 174, 0, 0.1);
}

.input-container {
  position: relative;
  height: 22%;
  margin-top: 16px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

textarea {
  width: 100%;
  height: 139px;
  padding: 12px;
  box-sizing: border-box;
  border: none;
  outline: none;
  resize: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}

.icon-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  bottom: 12px;
  right: 12px;
  gap: 10px;
}

.input-container select {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 1px;
}

.icon {
  cursor: pointer;
  font-size: 24px;
}

.add-button {
  width: 40px;
  height: 40px;
  background-color: #ffae00;
  transition: background-color 0.3s;  
  color: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pagination-container {
  display: flex;
  justify-content: right;
  margin-top: 15px;
  margin-right: 15px;
}

.pagination-button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pagination-button:hover {
  background-color: #f1f1f1;
}

.pagination-button.active {
  background-color: #ddd;
  color: #333;
}

.cta-pagination-container {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.cta {
  color: #333;
  padding: 10px 20px;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  width: 50%;
  border-radius: 10px;
  border-color: #ffae00;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.1);
}

.cta.inactive {
  background-color: #d3d3d3;
  color: #666;
}

.cta:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.cta input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
  outline: none;
}

.edit-icon {
  margin-left: 10px;
  display: none;
  cursor: pointer;
}

.cta:hover .edit-icon {
  display: inline;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination-button {
  border: none;
  background-color: #f0f0f0;
  color: #333;
  padding: 5px 10px;
  margin: 0 2px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-button:hover {
  background-color: #ddd;
}

.pagination-current {
  padding: 5px 10px;
  margin: 0 2px;
  font-weight: bold;
}

