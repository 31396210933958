/* App.css */
body, html {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  color: #333;
}

input[type="file"] {
  display: block;
  margin: 10px auto;
  padding: 10px;
  background: #fff;
  color: #333;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

input[type="file"]:hover {
  background: #eaeaea;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

th {
  background: #f9f9f9;
  color: #333;
}

button {
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  background: #fff;
  color: #333;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background: #eaeaea;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  background: #fff;
  color: #333;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:hover {
  background: #eaeaea;
}

.pagination .active {
  background: #ddd;
}

.tooltip {  
  padding: 10px;
  color: white;
  text-align: left;
  max-width: 200px;
}

.tooltip div {
  margin-bottom: 5px;
}

.editable-table-container {
  position: relative;
}

.add-column-button, .add-row-button {
  display: none;
  position: absolute;
  z-index: 1;
}

.add-column-button {
  top: 0;
  left: 0;
  height: 100%;
  width: 50px;
  transform: rotate(-90deg);
  transform-origin: top left;
}

.add-row-button {
  bottom: 0;
  left: 0;
  width: 100%;
}

.editable-table-container:hover .add-column-button,
.editable-table-container:hover .add-row-button {
  display: block;
}

/* Chat */
.chat-toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chat-icon {
  width: 50px;
  height: 50px;
  border: 4px solid white; /* Thick white border */
  border-radius: 50%;
  background-color: #FF6F61;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.chat-icon .inner-circle {
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
}

.chat-header {
  padding: 10px;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
}

.chat-minimize-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
}

.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: transparent;
}

.chat-message {
  margin-bottom: 10px;
}

.chat-message.user {
  text-align: right;
}