.d3-tip {
  background: white; /* Vibrant gradient background */
  backdrop-filter: blur(10px); /* Glassmorphism effect */
  width: 17rem; /* Increased width for better content fit */
  border-radius: 20px; /* Smooth, large rounded borders */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25); /* Enhanced shadow for 3D effect */
  color: #333333; /* Bright font color for contrast */
  padding: 15px; /* Increased padding for better spacing */
  font-size: 14px; /* Larger font size for readability */
  line-height: 1.6; /* Improved line height for readability */
  transition: transform 0.7s ease, box-shadow 0.7s ease; /* Smooth transition for hover effect */
}

.d3-tip:hover {
  transform: translateY(-5px); /* Subtle lift effect on hover */
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.4); /* Increased shadow for dramatic effect */
}