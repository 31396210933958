.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 42vh; /* Full viewport height */
  }
  
  .loading-spinner {
    border: 5px solid rgba(0,0,0,.1);
    border-radius: 50%;
    border-top: 5px solid blue; /* Use your brand color */
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }